import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import main_de from "./translations/de/main.json";
import main_en from "./translations/en/main.json";
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
      en: {
        main: main_en,
        common: common_en               // 'common' is our custom namespace
      },
      de: {
          main: main_de,
          common: common_de
      },
  },
});

const AppRouted = () => {
  let routes = useRoutes([
    { path: "/", element: <App lang='en'/> },
    { path: "/en", element: <App lang='en'/> },
    { path: "/de", element: <App lang='de'/> }
  ]);
  return routes;
};

const AppWrapper = () => {
  return (<Router><AppRouted/></Router>);
}

ReactDOM.render(
  <React.StrictMode>
        <I18nextProvider i18n={i18next}>
          <AppWrapper/>
        </I18nextProvider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
