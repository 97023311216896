import './App.css';
import React from 'react';
import { useTranslation } from "react-i18next";

function NavComponent(props)
{
    const {i18n} = useTranslation('menu');
    if(props.default && props.default.lang){
      i18n.changeLanguage(props.default.lang);
    } else {
      i18n.changeLanguage('en');
    }
    return <nav id="site-navigation" className="main-navigation nav-home nav" role="navigation">
    <button className="c-menu-toggle hamburger--boring js-menu-toggle hamburger" type="button">
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
    <ul id="navigation" className="list navigation">
      <li id="menu-item-11" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-11">
        <a href="/" aria-current="page">Home</a>
      </li>
      <li id="menu-item-12" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-12">
        <a className="smoothscroll" href="#about">About</a>
      </li>
      <li id="menu-item-12" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-12">
        <a className="smoothscroll" href="#resume">Resume</a>
      </li>
      {/* <li id="menu-item-13" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13">
        <a href="IsabelMoraRoblesCV.pdf" download="Isabel Mora CV.pdf">Download CV</a>
      </li> */}
      {/* <li id="menu-item-12" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-12">
        <a href='/en' >EN</a>/
        <a href='/de' >DE</a>
      </li> */}
    </ul>
  </nav>
}
function HeaderComponent(){
  const {t} = useTranslation('main');
  return <header className="header-home">
  <div className="selfie">
    <a href="#" className="custom-logo-link" rel="home" aria-current="page">
      <img width="799" height="799" src="ICMR.jpeg" className="custom-logo" alt="WP-Indigo" srcSet="ICMR.jpeg 799w, ICMR.jpeg 300w, ICMR.jpeg 150w, ICMR.jpeg 768w" sizes="(max-width: 799px) 100vw, 799px" />
    </a>
  </div>
  <div id="about">
    <a className="title-link" href="#">
      <h1 className="title">Isabel Mora Robles</h1>
    </a>
    <p>{t('about.me-1')}</p>
    <p>{t('about.me-2')}</p>
    <p>{t('about.me-3')}</p>
  </div>
</header>
}
function ResumeTimelineComponent(){
  const {t} = useTranslation('main');
  return <div className="row resume-timeline">
  <div className="col-twelve resume-header">
    <h1>{t('education.title')}</h1>
  </div>
  <div className="col-twelve">
    <div className="timeline-wrap">
      <div className="timeline-block">
        <div className="timeline-ico">
          <i className={'icon-book'} ></i>
        </div>
        <div className="timeline-header">
          <h3>{t('education.master.institution')}</h3>
          <h4>09.2018 - 10.2021</h4>
        </div>
        <div className="timeline-content">
          <h3>{t('education.master.title')}</h3>
          <p>{t('education.master.description')}</p>
        </div>
      </div>
      <div className="timeline-block">
        <div className="timeline-ico">
          <i className={'icon-book'} ></i>
        </div>
        <div className="timeline-header">
        <h3>{t('education.bachelor.institution')}</h3>
          <h4>03.2008 - 12.2013</h4>
        </div>
        <div className="timeline-content">
        <h3>{t('education.bachelor.title')}</h3>
          <p>{t('education.bachelor.description')}</p>
        </div>
      </div>
    </div>
  </div>
</div>
}
function ExperienceTimelineComponent(){
  const {t} = useTranslation('main');
  return <div className="row resume-timeline">
  <div className="col-twelve resume-header">
    <h1>{t('experience.title')}</h1>
  </div>
  <div className="col-twelve">
    <div className="timeline-wrap">
    <div className="timeline-block">
        <div className="timeline-ico">
          <i className={'icon-suitcase'} ></i>
        </div>
        <div className="timeline-header">
          <h3>{t('experience.discipline-engineer.organization')}</h3>
          <h4>02.2023 - Present</h4>
        </div>
        <div className="timeline-content">
          <h3>{t('experience.discipline-engineer.title')}</h3>
          <p>{t('experience.discipline-engineer.description')}</p>
        </div>
      </div>
      <div className="timeline-block">
        <div className="timeline-ico">
          <i className={'icon-suitcase'} ></i>
        </div>
        <div className="timeline-header">
          <h3>{t('experience.project-developer.organization')}</h3>
          <h4>04.2015 - 10.2021</h4>
        </div>
        <div className="timeline-content">
          <h3>{t('experience.project-developer.title')}</h3>
          <p>{t('experience.project-developer.description')}</p>
        </div>
      </div>
      <div className="timeline-block">
        <div className="timeline-ico">
          <i className={'icon-suitcase'} ></i>
        </div>
        <div className="timeline-header">
          <h3>{t('experience.civil-engineer.organization')}</h3>
          <h4>10.2013 - 02.2015</h4>
        </div>
        <div className="timeline-content">
          <h3>{t('experience.civil-engineer.title')}</h3>
          <p>{t('experience.civil-engineer.description')}</p>
        </div>
      </div>
      <div className="timeline-block">
        <div className="timeline-ico">
          <i className={'icon-suitcase'} ></i>
        </div>
        <div className="timeline-header">
        <h3>{t('experience.assistant-engineer.organization')}</h3>
          <h4>01.2013 - 09.2013</h4>
        </div>
        <div className="timeline-content">
          <h3>{t('experience.assistant-engineer.title')}</h3>
          <p>{t('experience.assistant-engineer.description')}</p>
        </div>
      </div>
      <br/>
      <div className="timeline-block">
        <div className="timeline-ico">
          <i className={'icon-suitcase'} ></i>
        </div>
        <div className="timeline-header">
          <h3>{t('experience.assistant-civil-engineer.organization')}</h3>
          <h4>01.2012 - 01.2013</h4>
        </div>
        <div className="timeline-content">
          <h3>{t('experience.assistant-civil-engineer.title')}</h3>
          <p>{t('experience.assistant-civil-engineer.description')}</p>
        </div>
      </div>
      <br/>
      <div className="timeline-block">
        <div className="timeline-ico">
          <i className={'icon-suitcase'} ></i>
        </div>
        <div className="timeline-header">
          <h3>{t('experience.teacher-assistant.organization')}</h3>
          <h4>07.2009 - 06.2013</h4>
        </div>
        <div className="timeline-content">
          <h3>{t('experience.teacher-assistant.title')}</h3>
          <p>{t('experience.teacher-assistant.description')}</p>
        </div>
      </div>
    </div>
  </div>
</div>
}
function FooterComponent() {
  return <footer className="footer-main">
          All rights reserved.<a className="link" href="https://demo.vitathemes.com/indigo/feed/" target="_blank" rel="noreferrer"></a>
        </footer>;
}
class App extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount () {
    const script = document.createElement("script");
    script.src = "script.js";
    script.async = true;
    document.body.appendChild(script);
  }
  render() {
    return (
      <div className="wrapper-normal">
        <div className="page">
          <NavComponent default={{lang: this.props.lang}}/>
          <HeaderComponent/>
          <section id="resume" className="grey-section">
            <ResumeTimelineComponent lang={{lang: this.props.lang}}/>
            <ExperienceTimelineComponent/>
          </section>
          <div className="social-links">
            <a aria-label="View linkedin page" className="link" data-title="https://www.linkedin.com/in/isabel-mora-robles/" href="https://www.linkedin.com/in/isabel-mora-robles/" target="_blank" rel="noreferrer">
              <label className="link-label">Be part of my network: <i className="icon icon-linkedin icon"></i></label>
            </a>
            {/* <a aria-label="Email me" className="link" data-title="isabel.mora.robles@gmail.com" href="mailto:isabel.mora.robles@gmail.com" target="_blank" rel="noreferrer">
              <label className="link-label">Or better yet, email me: <i className="icon icon-envelope icon"></i></label>
            </a> */}
          </div>
          {/* <div className="social-links">
            <a style={{backgroundColor: "#0E5484", "border": "none", "color": "white", "padding": "12px 16px", fontSize: "16px", "cursor": "pointer"}} rel="noopener" className="link" data-title="Download CV" href="IsabelMoraRoblesCV.pdf" download="Isabel Mora CV.pdf">
              <label className="link-label">Download CV <i style={{"color": "white"}} className="icon icon-download-alt icon"></i></label>
            </a>
          </div> */}
          <FooterComponent/>
        </div>
      </div>
    )
  };
}

export default App;
